import React from 'react';
import PropTypes from 'prop-types';
import { ShowIfProvider } from '@sprinx/react-showif';
// import { useAuthMeta, useUser, useScope } from '@sprinx/react-auth';

function AppShowIf({ children }) {
  // const user = useUser();
  // const meta = useAuthMeta();
  // const scope = useScope();

  return (
    <ShowIfProvider>
      {/* // adminGrant='sxcrm:admin'
      // scope={scope}
      // user={user}
      // meta={meta} */}
      {children}
    </ShowIfProvider>
  );
}

AppShowIf.propTypes = {
  children: PropTypes.node,
};

AppShowIf.defaultProps = {
  children: null,
};

export default AppShowIf;
