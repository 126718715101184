import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import LoaderOverlay from './LoaderOverlay';

const propTypes = {
  /**
   * Node, ktery bude prekryt loadingem. Ten se automaticky zobrazi vycentrovane do obsahu.
   */
  children: PropTypes.node,

  /**
   * Custom class name.
   */
  className: PropTypes.string,

  /**
   * Barva spinneru.
   */
  color: PropTypes.string,

  /**
   * `true` pokud nema byt zobrazn overlay pod spinnerem.
   */
  hideOverlay: PropTypes.bool,

  /**
   * `true` pokud se ma zobrazit loader.
   */
  loading: PropTypes.bool,

  /**
   * CSS barba pro pozadi overlay. Muzete pouzit i `rgba()`.
   */
  overlayColor: PropTypes.string,

  /**
   * Velikost loaderu
   */
  size: PropTypes.oneOfType([PropTypes.oneOf(['normal', 'big', 'small']), PropTypes.number]),

  /**
   * Custom styly.
   */
  style: PropTypes.shape({}),

  /**
   * Hrubka spinneru.
   */
  thickness: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),

  /**
   * Alias k properte `hideOverlay`.
   */
  transparent: PropTypes.bool,
};

const defaultProps = {
  children: null,
  className: undefined,
  color: '#7e3ff2',
  hideOverlay: false,
  loading: false,
  overlayColor: undefined,
  size: undefined,
  style: undefined,
  thickness: undefined,
  transparent: false,
};

function Loader(props) {
  const {
    children,
    className,
    color,
    hideOverlay,
    loading,
    overlayColor,
    size,
    style,
    thickness,
    transparent,
    ...rest
  } = props;

  const standAlone = children === null;
  const standAloneStyle = standAlone ? { padding: 24 } : {};
  const rootStyle = {
    position: 'relative',
    flexGrow: 1,
    ...standAloneStyle,
    ...style,
  };

  const spSize = typeof size === 'number'
    ? size
    : { normal: 40, big: 48, small: 32 }[size];

  return loading
    ? (
      <div {...rest} className={className} style={rootStyle}>
        {children}
        <LoaderOverlay transparent={hideOverlay || transparent || standAlone} color={overlayColor}>
          <div style={{ padding: 16, flex: '0 1 auto' }}>
            <Spinner color={color} size={spSize} thickness={thickness} />
          </div>
        </LoaderOverlay>
      </div>
    )
    : null;
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
