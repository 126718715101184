import React from 'react';
import styled from 'styled-components';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { Link } from 'react-router-dom';
import logo from './logo-axa.svg';

const StyledLogoLink = styled(Link)`
  visibility: visible;
  flex-shrink: 0;
  opacity: 1;
  transition: visibility 0s ease 0s,opacity .2s ease .2s;

  @media (min-width: 992px) {
    align-self: flex-start;
  }
`;

const StyledLogo = styled.img`
  height: 45px;

  @media (min-width: 768px) {
    height: 55px;
  }
`;

function PageLogo() {
  const t = useTranslate();
  return (
    <StyledLogoLink to='/'>
      <StyledLogo src={logo} alt={t('site/logo')} />
    </StyledLogoLink>
  );
}

export default PageLogo;
