import React from 'react';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import StyledHeader from './_StyledHeader';
import StyledHeaderMain from './_StyledHeaderMain';
import StyledHeaderContainer from './_StyledHeaderContainer';
import StyledTitle from './_StyledTitle';
import StyledSubtitle from './_StyledSubtitle';
import PageLogo from './PageLogo';


function PageHeader() {
  const t = useTranslate();
  return (
    <StyledHeader>
      <StyledHeaderMain>
        <StyledHeaderContainer>
          <PageLogo />
        </StyledHeaderContainer>
      </StyledHeaderMain>
      <StyledTitle>{t('site/title')}</StyledTitle>
      <StyledSubtitle>{t('site/subtitle')}</StyledSubtitle>
    </StyledHeader>
  );
}

export default PageHeader;
