import styled from 'styled-components';

const StyledHeader = styled.header`
  display: block;
  height: 175px;
  position: relative;
  width: 100%;
  trainsition: background-color 225ms cubic-bezier(.4,0,.6,1);
  background-color: #fff;
  z-index: 15;

  @media (min-width: 768px) {
    height: 215px;
  }
`;

export default StyledHeader;
