const csMessages = {
  sk: {
    site: {
      logo: 'AXA Ridesharing',
      title: 'Spolujazda s AXA',
      subtitle: 'Nájdite si svojho spolucestujúceho',
    },
    filterForm: {
      section: {
        track: 'Výber trasy',
        date: 'Termíny',
        direction: 'Smer',
      },
      dateFrom: {
        label: 'Od:',
        placeholder: 'Od',
        helperText: '',
      },
      dateTo: {
        label: 'Do:',
        placeholder: 'Do',
        helperText: '',
      },
      from: {
        label: 'Odkiaľ:',
        placeholder: 'Odkiaľ',
        helperText: '',
      },
      to: {
        label: 'Kam:',
        placeholder: 'Kam',
        helperText: '',
      },
      direction: {
        label: 'Směr:',
        placeholder: 'Směr',
        helperText: '',
      },
      submit: {
        label: 'Vyhľadať',
      },
    },
    cities: {
      prague: 'Praha',
      brno: 'Brno',
      bratislava: 'Bratislava',
    },
    direction: {
      there: 'Cesta iba tam',
      back: 'Cesta iba späť',
      thereAndBack: 'Cesta tam aj späť',
    },
    detailTable: {
      date: 'Dátum',
      department: 'Oddelenie',
      name: 'Meno',
    },
    ridesTeaser: {
      title: "Ako aplikácia funguje?"
    },
    secondSite: {
      title: 'Vyberte si spolujazdu',
      subtitle: 'Vyberte si kolegu, napíšte mu e-mail a rezervujte si svoju cestu. Dohodnite si spolu miesto odchodu, čas odchodu  a poprípade aj cestu svojho návratu.',
      backButton: 'Späť',
      endButton: 'Ukončiť'
    },
    sprinxFormsValidation: {
      required: 'Pole je povinné!',
      string: 'Musí být textový řetězec!',
      stringEmpty: 'Nesmí být prázdné!',
      stringMin: 'Délka musí být větší nebo rovné délce {expected} znaků!',
      stringMax: 'Délka musí být menší nebo rovna délce {expected} znaků!',
      stringLength: 'Délka musí být {expected} znaků dlouhá!',
      stringPattern: 'Nesplňuje požadovaný vzor!',
      stringContains: "Musí obsahovat text '{expected} '!",
      stringEnum: 'Nezodpovídá žádné z povolených hodnot!',
      number: 'Musí to být číslo!',
      numberMin: 'Musí být větší nebo roven {expected}!',
      numberMax: 'Musí být menší nebo rovno {expected}!',
      numberEqual: 'Musí být rovno s {expected}!',
      numberNotEqual: 'Nemůže být rovno s {expected}!',
      numberInteger: 'Musí být celé číslo!',
      numberPositive: 'Musí být kladné číslo!',
      numberNegative: 'Musí to být záporné číslo!',
      array: 'Musí to být seznam!',
      arrayEmpty: 'Nesmí být prázdný seznam!',
      arrayMin:
        'Je vyžadovaná alespoň {number, plural, one {1 položka} few {{number} položky} other {{number} položek}}!',
      arrayMax:
        'Nemůže obsahovat více než {number, plural, one {1 položku} few {{number} položky} other {{number} položek}}!',
      arrayLength:
        'Musí obsahovat přesně {expected, plural, one {1 položku} few {{number} položky} other {{number} položek}}!',
      arrayContains: "Musí obsahovat položku '{expected}'!",
      arrayEnum: "Hodnota '{expected}' neodpovídá žádné z povolených hodnot!",
      boolean: "Musí být 'ano' nebo 'ne'!",
      function: 'Musí být funkce!',
      date: 'Musí být datum!',
      dateMin: 'Musí být větší nebo roven {expected}!',
      dateMax: 'Musí být menší nebo roven {expected}!',
      forbidden: 'Pole je zakázáno!',
      email: 'Nevalidní e-mail!',
      url: 'Nevalidní URL!',
      object: 'Musí býť typu Object!',

      arrayReducedEquals: 'Sumarizovaná hodnota neodpovídá {expected}!',
      phoneNumber: "Musíte být platné telefonní číslo v mezinárodním formátu '+ XXX XXX XXX XXX '!",
      dateGt: 'Datum musí být větší než {expected}!',
      dateGte: 'Datum musí být větší nebo rovné {expected}!',
      dateLt: 'Datum musí být menší než {expected}!',
      dateLte: 'Datum musí být menší nebo rovné {expected}!',
    },
    components: {
      textField: {
        errorMsgWrapper: 'Pole nesmí být prázdné!'
      }
    }
  },
};

export default csMessages;
