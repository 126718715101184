import styled from 'styled-components';

const StyledTitle = styled.h1`
  margin: 0;
  padding-top: 20px;
  text-align: center;
  line-height: 34px;
  font-size: 30px;
  letter-spacing: 0.1px;
  font-weight: bold;
  font-family: 'Publico-Banner';
  color: #914146;

  @media (min-width: 768px) {
    padding-top: 31px;
    line-height: 1.1;
    font-size: 48px;
  }
`;

export default StyledTitle;
