import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  transparent: PropTypes.bool.isRequired,
};

const defaultProps = {
  color: 'rgba(1,1,1,0.1)',
};

/**
 * @ignore
 * @deprecated
 */
function LoaderOverlay({ children, color, transparent }) {
  const style = {
    position: 'absolute', /* Sit on top of the page content */
    width: '100%', /* Full width (cover the whole page) */
    height: '100%', /* Full height (cover the whole page) */
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: transparent ? 'rgba(0,0,0,0)' : color, /* Black background with opacity */
    zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
    cursor: 'pointer', /* Add a pointer on hover */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={style}>
      {children}
    </div>
  );
}

LoaderOverlay.propTypes = propTypes;
LoaderOverlay.defaultProps = defaultProps;

export default LoaderOverlay;
