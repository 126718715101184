import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PageHeader from '../PageHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(6),
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}));

function MainLayout({
  children,
  className,
  render,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Container {...rest} className={clsx(classes.root, className)}>
      <PageHeader />
      <div className={classes.content}>
        {render ? render() : children}
      </div>
    </Container>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  render: PropTypes.func,
};

MainLayout.defaultProps = {
  children: null,
  className: undefined,
  render: undefined,
};

export default MainLayout;
