import React from 'react';
import PropTypes from 'prop-types';
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
import CldrLocaleCs from '@sprinx/react-globalize/CldrLocaleCs';
import CldrLocaleSk from '@sprinx/react-globalize/CldrLocaleSk';
import MessagesCs from './MessagesCs';
import MessagesSk from './MessagesSk';

function AppGlobalize({
  children,
  defaultLanguage,
  onPreferredLanguageChanged,
  supportedLanguages,
}) {

  return (
    <GlobalizeProvider
      cldrs={{ sk: CldrLocaleSk, cs: CldrLocaleCs }}
      messages={{ cs: MessagesCs, sk: MessagesSk }}
      defaultLocale={defaultLanguage}
      supportedLocales={supportedLanguages}
      onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
    >
      {children}
    </GlobalizeProvider>
  );
}

AppGlobalize.propTypes = {
  children: PropTypes.node.isRequired,
  defaultLanguage: PropTypes.string,
  onPreferredLanguageChanged: PropTypes.func,
  supportedLanguages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    ]),
  ).isRequired,
};

AppGlobalize.defaultProps = {
  defaultLanguage: 'cs',
  onPreferredLanguageChanged: undefined,
};

export default AppGlobalize;
