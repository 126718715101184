import PublicoBanner from './fonts/Publico-Banner-Bold.woff';

const publico = {
  fontFamily: 'Publico-Banner',
  src: `
    local('Publico-Banner'),
    url(${PublicoBanner}) format('woff')
  `
}

const themePalette = {
  palette: {
    primary: {
      main: '#00008f',
      dark: '#00005b',
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", Arial, sans-serif',
    letterSpacing: 0.1,
    h1: { fontFamily: '"Publico-Banner", Arial, sans-serif', letterSpacing: 0.1 },
    h2: { fontFamily: '"Publico-Banner", Arial, sans-serif', letterSpacing: 0.1, fontWeight: 700, fontSize: 36, },
    h3: { fontFamily: '"Publico-Banner", Arial, sans-serif', letterSpacing: 0.1 },
  },
};

const themeAxa = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [publico]
      }
    },
    typography: {
      fontFamily: [
        'Source Sans Pro',
        publico,
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    MuiTypography: {
      h2: {
        fontSize: '36px',
        fontFamily: '"Publico-Banner"',
        fontWeight: '700',
        borderBottom: '1px solid #ccc',
        paddingBottom: 25,
        marginBottom: 25,
        fontWeight: 700,
        fontSize: 36,
        '@media(min-width: 1280px)': { fontSize: 36 },
        '@media(min-width: 960px)': { fontSize: 36 },
        '@media(min-width: 600px)': { fontSize: 36 },
      },
      h3: {
        fontWeight: '700',
        paddingBottom: '12px',
        fontFamily: 'Source Sans Pro',
        fontSize: 24,
        '@media(min-width: 1280px)': { fontSize: 24 },
        '@media(min-width: 960px)': { fontSize: 24 },
        '@media(min-width: 600px)': { fontSize: 24 },
      },
      h4: {
        fontSize: '24px',
        fontFamily: 'Source Sans Pro',
        fontWeight: '700',
        textAlign: 'center',
        paddingTop: '12px',
        paddingBottom: '24px',
        fontSize: 24,
        '@media(min-width: 1280px)': { fontSize: 24 },
        '@media(min-width: 960px)': { fontSize: 24 },
        '@media(min-width: 600px)': { fontSize: 24 },
      },
      body1: {
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    MuiTableCell: {
      root: {
        borderLeft: '1px solid rgba(0,0,0,.1)',
        borderRight: '1px solid rgba(0,0,0,.1)',
        fontFamily: 'Source Sans Pro',
      },
    },
    MuiContainer: {
      root: {
        '@media(min-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      maxWidthLg: {
        '@media(min-width: 1140px)': {
          maxWidth: 1140,
        },
      },
    },
    // Formularove prvky
    MuiMenuItem: {
      root: {
        color: '#999',
        fontSize: 14,
        minHeight: 'unset',
        fontFamily: '"Source Sans Pro", Arial, sans-serif',

        '&:hover': {
          color: '#00008f',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 28,
        marginTop: null,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: '"Source Sans Pro",Arial,sans-serif',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: -50,
        top: '50%',
        transform: 'translate(0,-50%)',
      },
    },
    MuiInput: {
      root: {
        position: 'relative',
      },
      focused: {
        background: null,
      },
      underline: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
      formControl: {
        'label + &': {
          marginTop: null,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: '"Source Sans Pro",Arial,sans-serif',
        fontSize: 19,
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid #ccc',
        fontSize: '18px',
        fontFamily: themePalette.typography.fontFamily,
        lineHeight: '28px',
        fontWeight: '400',
        color: '#999',
        '&:hover': {
          borderColor: themePalette.palette.primary.main,
        },
      },
      input: {
        padding: '10px 14px 10px 14px',
        background: '#fff',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#292b2c',
        marginBottom: '14px',
      },
      formControl: {
        position: 'static',
        top: null,
        left: null,
        transform: null,
      },
      shrink: {
        transform: null,
      },
    },
    MuiSelect: {
      select: {
        paddingRight: '50px',
        '&:focus': {
          backgroundColor: null,
        },
      },
      selectMenu: {
        background: '#fff',
      },
    },
    // Button
    MuiButton: {
      root: {
        borderRadius: 0,
        border: 'none',
        borderBottom: '2px solid transparent',
        lineHeight: 1.5,
        backgroundColor: 'transparent',
        color: '#ffffff',
        zIndex: 0,
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: themePalette.typography.fontFamily,
        transition: 'all .3s ease',
        letterSpacing: 'normal',
        overflow: 'hidden',
        padding: '12px 32px',
        '&$disabled': {
          backgroundColor: '#d0d0d0',
          borderBottom: 'none',
          color: '#fff',
          opacity: 0.65,
          cursor: 'not-allowed',
        },
      },
      outlinedPrimary: {
        border: '2px solid',
        borderColor: themePalette.palette.primary.main,
        color: themePalette.palette.primary.main,
        background: '#fff',
        '&:hover': {
          border: '2px solid',
          borderColor: themePalette.palette.primary.main,
          backgroundColor: themePalette.palette.primary.main,
          color: '#ffffff',
        },
        '&:after': {
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '0',
          height: '530px',
          transform: 'translate(-50%,-50%) rotate(45deg)',
          transition: 'all .3s ease',
          backgroundColor: themePalette.palette.primary.dark,
          content: '""',
          opacity: 0,
          zIndex: '-1',
          backfaceVisibility: 'hidden',
        },
        '&:hover:after': {
          width: '110%',
          opacity: 1,
        },
      },
      textPrimary: {
        backgroundColor: themePalette.palette.primary.main,
        borderBottom: '2px solid',
        borderBottomColor: themePalette.palette.primary.dark,
        color: '#ffffff',
        '&:hover': {
          backgroundColor: themePalette.palette.primary.main,
          color: '#ffffff',
        },
        '&:after': {
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '0',
          height: '530px',
          transform: 'translate(-50%,-50%) rotate(45deg)',
          transition: 'width 0.3s, opacity 0.3s',
          backgroundColor: themePalette.palette.primary.dark,
          content: '""',
          opacity: 0,
          zIndex: '-1',
          backfaceVisibility: 'hidden',
        },
        '&:hover:after': {
          width: '110%',
          opacity: 1,
        },
      },
      sizeLarge: {
        padding: '0.8rem 2rem',
        fontSize: '1.2rem',
      },
      outlined: {
        padding: '12px 32px',
      },
    },
  },
};

export default themeAxa;
