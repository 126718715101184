import React, { /* useEffect,*/ lazy } from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from '@sprinx/react-routes/PublicRoute';
import MainLayout from './components/MainLayout';
import Loader from './components/Loader';

const RidesPage = lazy(() => import('./rides/RidesPage'));
const RideDetailPage = lazy(() => import('./rides/RideDetailPage'));

function AppRoutes(/* props */) {
  return (
    <React.Suspense fallback={<Loader />}>
      <Switch>
        <PublicRoute
          layout={MainLayout}
          path='/'
          exact
          component={RidesPage}
        />

        <PublicRoute
          layout={MainLayout}
          path='/results'
          component={RideDetailPage}
        />
      </Switch>
    </React.Suspense>
  );
}

AppRoutes.propTypes = {};
AppRoutes.defaultProps = {};

export default AppRoutes;
