import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import AppContainer from '@sprinx/react-mui-layout/AppContainer';
import LayoutProvider from '@sprinx/react-mui-layout/LayoutProvider';
import MuiComponentsProvider from '@sprinx/react-mui-components/MuiComponentsProvider';
import MuiFieldsProvider from '@sprinx/react-mui-fields/MuiFieldsProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DatePickerUtils from '@sprinx/date-picker-utils/DateFnsUtils';
import Scrollbars from '@sprinx/react-mui-components/Scrollbars';
import ProductionAppRoot from '@sprinx/react-mui-layout/ProductionAppRoot';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppGlobalize from './AppGlobalize';
import AppShowIf from './AppShowIf';
import AppRoutes from './AppRoutes';
import supportedLanguages from './supportedLanguages';
import './theme/css/style.css';
import themeAxa from './theme';

const applicationTitle = 'AXA Ride Sharing';

const defaultLocale = process.env.REACT_APP_BUILD_LANG || 'cs';

function App() {
  return (
    <AppContainer
      defaultTitle={applicationTitle}
      RootComponent={ProductionAppRoot}
      ErrorComponent={() => <h1>Something went wrong.</h1>}
      themeSettings={themeAxa}
    >
        <CssBaseline />
        <AppGlobalize supportedLanguages={supportedLanguages} defaultLanguage={defaultLocale}>
          <AppShowIf>
                  <MuiPickersUtilsProvider utils={DatePickerUtils}>
                    <LayoutProvider title={applicationTitle}>
                      <MuiComponentsProvider
                        currencyTypes={['EUR']}
                        linkComponent={ReactRouterLink}
                        supportedLanguages={supportedLanguages}
                        >
                        <MuiFieldsProvider currencyTypes={['EUR']} supportedLanguages={supportedLanguages}>
                          <Scrollbars style={{ background: '#fff' }}>
                            <AppRoutes />
                          </Scrollbars>
                        </MuiFieldsProvider>
                      </MuiComponentsProvider>
                    </LayoutProvider>
                  </MuiPickersUtilsProvider>
          </AppShowIf>
        </AppGlobalize>
    </AppContainer>
  );
}

App.displayName = 'App';
App.propTypes = {};
App.defaultProps = {};

export default App;
