// import React from 'react';
import PropTypes from 'prop-types';
// import compMessages from '@sprinx/react-mui-components/i18n/en';
// import fieldMessages from '@sprinx/react-mui-fields/i18n/en';
// import tablesMessages from '@sprinx/react-mui-tables/i18n/en';
// import formsMessages from '@sprinx/react-forms/i18n/en';
// import pagesMessages from '@sprinx/react-mui-pages/i18n/en';
// import shoppingListMessages from '@sprinx/react-shopping-lists/i18n/en';
// import comMessages from '@sprinx/react-com/i18n/en';
// import shoppingCartMessages from '@sprinx/react-shopping-cart/i18n/en';
// import comOrdersMessages from '@sprinx/react-com-orders/i18n/en';
import messages from './i18n/sk';

function MessagesEn({ children }) {
  return children([
    // compMessages,
    // fieldMessages,
    // tablesMessages,
    // formsMessages,
    // pagesMessages,
    // comMessages,
    // shoppingListMessages,
    // shoppingCartMessages,
    // comOrdersMessages,
    messages,
  ]);
}

MessagesEn.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesEn;
