import styled from 'styled-components';

const StyledSubtitle = styled.span`
  display: block;
  text-align: center;
  font-size: 19.2px;
  letter-spacing: 0.1px;
  font-family: 'Source Sans Pro', Arial, sans-serif;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export default StyledSubtitle;
