import styled from 'styled-components';

const StyledHeaderMain = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  height: 72px;
  transition: border-bottom 225ms cubic-bezier(.4,0,.6,1);
`;

export default StyledHeaderMain;
