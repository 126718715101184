import React from 'react';
import PropTypes from 'prop-types';

const speedSwitch = (speed) => {
  if (speed === 'fast') return 600;
  if (speed === 'slow') return 900;
  return 750;
};

function Spinner(props) {
  const {
    color,
    gap,
    size,
    speed,
    thickness,
    ...other
  } = props;
  return (
    <svg
      height={size}
      width={size}
      {...other}
      style={{ animationDuration: `${speedSwitch(speed)}ms` }}
      className='__react-svg-spinner_circle'
      role='img'
      aria-labelledby='title desc'
      viewBox='0 0 32 32'
    >
      <title id='title'>Circle loading spinner</title>
      <desc id='desc'>{'Image of a partial circle indicating "loading."'}</desc>
      <style
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: `
          .__react-svg-spinner_circle{
              transition-property: transform;
              animation-name: __react-svg-spinner_infinite-spin;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
          }
          @keyframes __react-svg-spinner_infinite-spin {
              from {transform: rotate(0deg)}
              to {transform: rotate(360deg)}
          }`,
        }}
      />
      <circle
        role='presentation'
        cx={16}
        cy={16}
        r={14 - (thickness / 2)}
        stroke={color}
        fill='none'
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap='round'
      />
    </svg>
  );
}

Spinner.propTypes = {
  color: PropTypes.string,
  gap: PropTypes.oneOf([1, 2, 3, 4, 5]),
  size: PropTypes.number,
  speed: PropTypes.oneOf(['normal', 'fast', 'slow']),
  thickness: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
};

Spinner.defaultProps = {
  color: 'rgba(0,0,0,0.4)',
  gap: 4,
  size: 40,
  speed: 'normal',
  thickness: 3,
};

export default Spinner;
